import { css } from "@emotion/react";

import {
  fontFamily,
  fontBold,
  fontMedium,
  fontBoldItalic,
  fontHeavy,
  fontHeavyItalic,
  fontLight,
  fontLightItalic,
  fontMediumItalic,
  fontRegular,
  fontRegularItalic,
  fontSemiBold,
  fontSemiBoldItalic,
} from "./fonts";

const globalStyle = css`
  html {
    scroll-behavior: smooth;
  }

  @font-face {
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 300;
    src: url(${fontLight}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: italic;
    font-weight: 300;
    src: url(${fontLightItalic}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 400;
    src: url(${fontRegular}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: italic;
    font-weight: 400;
    src: url(${fontRegularItalic}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 500;
    src: url(${fontMedium}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: italic;
    font-weight: 500;
    src: url(${fontMediumItalic}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 600;
    src: url(${fontSemiBold}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: italic;
    font-weight: 600;
    src: url(${fontSemiBoldItalic}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 700;
    src: url(${fontBold}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: italic;
    font-weight: 700;
    src: url(${fontBoldItalic}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 900;
    src: url(${fontHeavy}) format("opentype");
    font-display: swap;
  }
  @font-face {
    font-family: ${fontFamily};
    font-style: italic;
    font-weight: 900;
    src: url(${fontHeavyItalic}) format("opentype");
    font-display: swap;
  }
`;

export default globalStyle;
